@import url('https://fonts.googleapis.com/css2?family=Salsa&display=swap');


:root {
  --tw-bg-opacity: 0.5; /* Adjust the opacity as needed */
}



*{
  font-family: 'YourCustomFont' !important;
  font-size: 20px !important;
}
.deskz{

}
body {
 
 background-color: #ffffff !important;
}
@font-face {
  font-family: 'YourCustomFont';
  src: url('./Fonts/MohrRoundedAlt-Bold.ttf') format('truetype');
  /* You can also specify font-weight, font-style, etc. */
}
.bgc{
  cursor: url(chand.png) !important;
  background-attachment: fixed;
background-image: url('./bgc.gif');
  background-repeat: no-repeat!important;
  background-size: cover !important;

  /* Set the background color with opacity and color using a CSS variable */
 
}
.bgcm {
  cursor: url(chand.png) !important;
  background-attachment: fixed;
  background-image: url('./bgc.gif');
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center center; /* Adjust this value to move the image slightly to the left */
}

.mob{
  cursor: url(chand.png) !important;
  background-attachment: fixed;

  background-repeat: no-repeat!important;
  background-size: cover !important;
  background-position: center;

  /* Set the background color with opacity and color using a CSS variable */

}

.centerall{
  text-align: center;
  justify-content: center;
  align-items: center;

}

/* Additional font-face rules if you have more fonts */

/* Define global font styles */

.backall{
  background-color: #ffffff00;
  position: fixed;
  top: 0;
  left: 0;
  color: #000 !important;
  width: 100%;
  height: 60px
}

.image_anos{
  width: 80%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anos{
    width: 100%;
  }

      }



.anos_text{
  font-size: 3rem !important;
  color: rgb(0, 0, 0);
  font-family: Courier New,Courier,monospace;
  
}


@media screen and (min-width: 1000px){
  .anos_text{
    font-size: 7rem !important;
    color: rgb(0, 0, 0);
    font-family: Courier New,Courier,monospace;
   
  }
  
}


.anos_textg{
  font-size: 1rem !important;
  color: rgb(0, 0, 0);
  font-weight: 300 !important;

  
}


@media screen and (min-width: 1000px){
  .anos_textg{
    font-size: 2.5rem !important;
    color: rgb(0, 0, 0);
    font-weight: 300 !important;

    
  }
  
  
}




.anon_p{
font-size: 13px !important;
  color: rgb(0, 0, 0);
  font-weight: 600;
 
}


@media screen and (min-width: 1000px){
  .anon_p{
    font-size: 24px !important;
   font-weight: 600;
  color: rgb(0, 0, 0);
 
  }
  
}

.image_anos_uni{
  width: 60%;
}

@media screen and (min-width: 1000px){
  .image_anos_uni{
    width: 50%;
  }
}

.image_anos_dex{
  width: 55%;
}

@media screen and (min-width: 1000px){
  .image_anos_dex{
    width: 45%;
  }
}

.matrix-text {
  color: rgb(0, 0, 0) !important;
  font-size: 19px !important;
  transition: color 0.3s ease-in-out, transform 0.3s ease-in-out;
}

@media screen and (min-width: 1000px) {
  .matrix-text {
    font-size: 35px !important;
  }
}

.matrix-text:hover {
  color: rgb(74, 171, 0) !important; /* Change to your desired hover color */
  animation: popOutAnimation 0.6s forwards;
}

@keyframes popOutAnimation {
  0% {
    transform: scale(1);
    text-shadow: none;
  }
  50% {
    transform: scale(1.3);
    text-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  }
  100% {
    transform: scale(1);
    text-shadow: none;
  }
}

.blue-text {
  font-size: 1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .blue-text {
    color: #ffffff;
    font-size: 2.5rem !important;
    font-family: Courier New,Courier,monospace;
    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.red-text {
  font-size:1rem !important;
  color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .red-text {

    font-size: 2.5rem !important;
    color: #ffffff;
  font-family: Courier New,Courier,monospace;
  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}



.token_text{
  font-size: 1.4rem !important;
  color: rgb(255, 255, 255);

  text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
}


@media screen and (min-width: 1000px){
  .token_text{
    font-size: 1.4rem !important;
    color: rgb(255, 255, 255);

    text-shadow: 0 0 2px rgb(4, 89, 201), 0 0 4px rgb(120, 64, 239), 0 0 6px rgb(166, 29, 252), 0 0 8px rgb(108, 0, 151), 0 0 10px rgb(140, 60, 215), 0 0 12px rgb(60, 0, 143), 0 0 14px rgb(102, 1, 148), 0 0 16px rgb(113, 0, 166);
  }
  
}










.fotx{
  color: white !important
  
}





















@import"https://fonts.googleapis.com/css2?family=Fredoka+One&display=swap";.inputCls[type=number] {
  -moz-appearance: textfield
}


.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #22c55e !important;
  width: 100%;
  height: 50px
}



.boarding {
  position: absolute;
  top: -4px;
  left: -4px;
  width: calc(100% + 8px);
  height: calc(100% + 8px);
  border: 4px solid #bb00ff;
  pointer-events: none;
  border-radius: 12px;
  opacity: 0;
  animation: boardingAnimation 2s linear infinite
}

@keyframes boardingAnimation {
  0% {
      opacity: 0
  }

  50% {
      opacity: 1
  }

  to {
      opacity: 0
  }
}

.preloader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
  background-repeat: repeat
}

*,:before,:after {
  box-sizing: border-box;
  border-width: 0;
  border-style: solid;
  border-color: #e5e7eb
}

:before,:after {
  --tw-content: ""
}

html {
  line-height: 1.5;
  -webkit-text-size-adjust: 100%;
  -moz-tab-size: 4;
  -o-tab-size: 4;
  tab-size: 4;
  font-family: ui-sans-serif,system-ui,-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica Neue,Arial,Noto Sans,sans-serif,"Apple Color Emoji","Segoe UI Emoji",Segoe UI Symbol,"Noto Color Emoji";
  font-feature-settings: normal
}



.tokcolo{
  color:  #fff !important;
  font-size: 3rem !important;
}




/* Style the audio container */
audio {
  background-color:transparent !important;
  color: white;
  width: 300px;
}

/* Style the audio controls */
audio::-webkit-media-controls {
  background-color: #a306067b;
  border-radius: 40%;
}

audio::-webkit-media-controls-play-button {
  background-color: #7c040481;
}

audio::-webkit-media-controls-volume-slider {
  background-color: #ff222263;
}

audio::-webkit-media-controls-panel {
  background-color: #860000d8;
  color: white;
}

/* Style the audio controls for Firefox */
audio::-moz-media-controls {
  background-color: #444;
  color: white;
}

/* Style the audio controls for Edge/IE */
audio::-ms-media-controls {
  background-color: #444;
  color: white;
}





.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #5a41fea9 !important;
  background-clip: padding-box;
  border: 1px solid rgba(0,0,0,.2);
  border-radius: 0.5rem;
  outline: 0;
}







































































:root {
  --glow-color: hsla(0, 0%, 100%, 0.882);
}




.glowing-btn {
  position: relative;
  color: var(--glow-color);
  cursor: pointer;
  padding: 0.25em 1em;
  border: 0.15em solid var(--glow-color);
  border-radius: 0.45em;
 background-color: #000000;
  perspective: 2em;
  font-family: Courier New,Courier,monospace;
  font-size: 2em;
  font-weight: 700;
  letter-spacing: 1em;

  -webkit-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  -moz-box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  box-shadow: inset 0px 0px 0.5em 0px var(--glow-color),
    0px 0px 0.5em 0px var(--glow-color);
  animation: border-flicker 2s linear infinite;
}

.glowing-txt {
  float: left;
  margin-right: -0.8em;
  -webkit-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  -moz-text-shadow: 0 0 0.125em hsl(0, 0%, 0%),
    0 0 0.45em var(--glow-color);
  text-shadow: 0 0 0.125em hsl(0 0% 100% / 0.3), 0 0 0.45em var(--glow-color);
  animation: text-flicker 3s linear infinite;
}

.faulty-letter {
  opacity: 0.5;
  animation: faulty-flicker 2s linear infinite;
}

.glowing-btn::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0.7;
  filter: blur(1em);
  transform: translateY(120%) rotateX(95deg) scale(3, 0.35);
  background: var(--glow-color);
  pointer-events: none;
}

.glowing-btn::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  z-index: -1;
  background-color: var(--glow-color);
  box-shadow: 0 0 2em 0.2em var(--glow-color);
  transition: opacity 100ms linear;
}

.glowing-btn:hover {
  color: rgba(0, 0, 0, 0.8);
  text-shadow: none;
  animation: none;
}

.glowing-btn:hover .glowing-txt {
  animation: none;
}

.glowing-btn:hover .faulty-letter {
  animation: none;
  text-shadow: none;
  opacity: 1;
}

.glowing-btn:hover:before {
  filter: blur(1.5em);
  opacity: 1;
}

.glowing-btn:hover:after {
  opacity: 1;
}

@keyframes faulty-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 0.1;
  }
  4% {
    opacity: 0.5;
  }
  19% {
    opacity: 0.5;
  }
  21% {
    opacity: 0.1;
  }
  23% {
    opacity: 1;
  }
  80% {
    opacity: 0.5;
  }
  83% {
    opacity: 0.4;
  }

  87% {
    opacity: 1;
  }
}

@keyframes text-flicker {
  0% {
    opacity: 0.1;
  }

  2% {
    opacity: 1;
  }

  8% {
    opacity: 0.1;
  }

  9% {
    opacity: 1;
  }

  12% {
    opacity: 0.1;
  }
  20% {
    opacity: 1;
  }
  25% {
    opacity: 0.3;
  }
  30% {
    opacity: 1;
  }

  70% {
    opacity: 0.7;
  }
  72% {
    opacity: 0.2;
  }

  77% {
    opacity: 0.9;
  }
  100% {
    opacity: 0.9;
  }
}

@keyframes border-flicker {
  0% {
    opacity: 0.1;
  }
  2% {
    opacity: 1;
  }
  4% {
    opacity: 0.1;
  }

  8% {
    opacity: 1;
  }
  70% {
    opacity: 0.7;
  }
  100% {
    opacity: 1;
  }
}

@media only screen and (max-width: 600px) {
  .glowing-btn{
    font-size: 1em;
  }
}




.dextop{
  position: relative;
  top: 8px;
}

































































































button {

  margin: 0;
  padding: 0.8em 1em;
  outline: none;
  text-decoration: none;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  border: none;
  text-transform: uppercase;
  background-color: #333;
  border-radius: 10px;
  color: #fff;
  font-weight: 300;
  font-size: 18px;
  font-family: inherit;
  z-index: 0;
  overflow: hidden;
  transition: all 0.3s cubic-bezier(0.02, 0.01, 0.47, 1);
}

button:hover {
  animation: sh0 0.5s ease-in-out both;
}

@keyframes sh0 {
  0% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }

  25% {
    transform: rotate(7deg) translate3d(0, 0, 0);
  }

  50% {
    transform: rotate(-7deg) translate3d(0, 0, 0);
  }

  75% {
    transform: rotate(1deg) translate3d(0, 0, 0);
  }

  100% {
    transform: rotate(0deg) translate3d(0, 0, 0);
  }
}

button:hover span {
  animation: storm 0.7s ease-in-out both;
  animation-delay: 0.06s;
}

button::before,
button::after {
  content: '';
  
  right: 0;
  bottom: 0;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: #fff;
  opacity: 0;
  transition: transform 0.15s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.15s cubic-bezier(0.02, 0.01, 0.47, 1);
  z-index: -1;
  transform: translate(100%, -25%) translate3d(0, 0, 0);
}

button:hover::before,
button:hover::after {
  opacity: 0.15;
  transition: transform 0.2s cubic-bezier(0.02, 0.01, 0.47, 1), opacity 0.2s cubic-bezier(0.02, 0.01, 0.47, 1);
}

button:hover::before {
  transform: translate3d(50%, 0, 0) scale(0.9);
}

button:hover::after {
  transform: translate(50%, 0) scale(1.1);
}

















.image_anosl{
  width: 100%;
}
@media screen and (min-width: 1000px) {
                        
                                
  .image_anosl{
    width: 40%;
  }

      }












      .card {
        width: 100%;
        height: 100%;
        z-index: -1;
        background: rgb(0, 0, 0);
        border-radius: 10px;
        transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
       }
       
       .shadow {
        box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
                    0 0  0 2px rgb(190, 190, 190),
                    0.3em 0.3em 1em rgba(0,0,0,0.3);
       }
       

       @media screen and (min-width: 1000px) {
                        
                                
        .card {
          width: 100%;
          height: 294px;
          background: rgb(0, 0, 0);
          border-radius: 10px;
          transition: border-radius 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
         }
         
         .shadow {
          box-shadow: inset 0 -3em 3em rgba(0,0,0,0.1),
                      0 0  0 2px rgb(190, 190, 190),
                      0.3em 0.3em 1em rgba(0,0,0,0.3);
         }
         
            }










       .htb_h{
        font-size: 24px !important;
        font-weight: 900;
       }
       @media screen and (min-width: 1000px){
        .htb_h{
          font-size: 30px !important;
         }
      }
      
       
       .htb_hp{
        font-size: 15px !important;
        font-weight: 500;
       }
       @media screen and (min-width: 1000px){
        .htb_hp{
          font-size: 19px !important;
         }
      }








      .center_dis{
        font-size: 6px !important;
        text-align: center;
      }

      
      .center_diss{
        font-size: 10px !important;
        text-align: center;
      }


      h2{
        color: #fff !important;
        font-size: 40px !important;
        font-weight: 600;
      }
      
      h3{
        color: #fff !important;
        font-size: 40px !important;
        font-weight: 600;
      }

      .fontx{
        font-size: 50px !important;
      }












      
      h4{
        color: #fff !important;
        font-size: 20px !important;
        font-weight: 500;
      }
      
      h5{
        color: #fff !important;
        font-size: 12px !important;
        font-weight: 500;
      }

      .fontx{
        font-size: 50px !important;
      }